exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-celebration-index-jsx": () => import("./../../../src/pages/celebration/index.jsx" /* webpackChunkName: "component---src-pages-celebration-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-gift-index-jsx": () => import("./../../../src/pages/gift/index.jsx" /* webpackChunkName: "component---src-pages-gift-index-jsx" */),
  "component---src-pages-parents-day-index-jsx": () => import("./../../../src/pages/parents-day/index.jsx" /* webpackChunkName: "component---src-pages-parents-day-index-jsx" */),
  "component---src-pages-product-index-jsx": () => import("./../../../src/pages/product/index.jsx" /* webpackChunkName: "component---src-pages-product-index-jsx" */),
  "component---src-pages-vase-index-jsx": () => import("./../../../src/pages/vase/index.jsx" /* webpackChunkName: "component---src-pages-vase-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-3-service-index-jsx": () => import("./../../../src/templates/3_service/index.jsx" /* webpackChunkName: "component---src-templates-3-service-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

